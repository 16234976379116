<template>
  <n-config-provider
    class="flex flex-col h-screen w-screen"
    :hljs="hljs"
    :theme="gl.darkTheme ? darkTheme : null"
    :theme-overrides="themeOverrides"
  >
    <n-notification-provider>
      <n-message-provider>
        <root-component-mobile v-if="gl.isMobile" />
        <root-component v-else />
        <template v-if="gl.init">
          <n-modal :show="gl.showLoading" transform-origin="center">
            <div class="flex flex-col justify-center items-center">
              <n-spin size="large" />
              <div
                v-if="gl.loadingMessage"
                class="text-center text-base text-main mt-4"
              >
                {{ gl.loadingMessage }}
              </div>
            </div>
          </n-modal>
          <section class="flex max-w-full">
            <left-panel v-if="!gl.isMobile" />
            <section
              class="w-full lg:w-[calc(100%-80px)] bg-gray-100 dark:bg-darkbg"
            >
              <rb-mobile-header v-if="gl.isMobile" />
              <rb-header v-else />
              <div
                id="main_container"
                class="w-full m-x-auto min-h-screen lg:min-h-[0] lg:h-[calc(100vh-80px)] lg:px-12 lg:overflow-y-scroll pt-14 lg:pt-0 lg:pb-12"
              >
                <!-- class="max-w-[1300px] mx-auto" -->
                <section
                  :class="
                    gl.isMobile && showPostAdviceMessages ? 'pb-[280px]' : ''
                  "
                >
                  <router-view v-if="!gl.twoFactorAuth" />
                </section>
                <mobile-menu v-if="gl.isMobile" />
              </div>
            </section>
          </section>
          <template v-if="taskResult?.type === 'alert'">
            <base-modal-mobile
              v-if="gl.isMobile"
              :maskClosable="false"
              :showCLoseButton="false"
              :type="taskResult?.data.type"
              :label="taskResult?.data.title"
              v-model:show="taskResult.show"
            >
              <template #default>
                <div
                  v-if="taskResult.data.allowHtml"
                  v-html="taskResult.data.content"
                ></div>
                <div v-else>{{ taskResult.data.content }}</div>
              </template>
              <template #footer>
                <div class="flex justify-end">
                  <n-button
                    size="small"
                    class="text-white/90 rounded-md"
                    @click="someAction(taskResult.data)"
                    :type="taskResult?.data.type"
                    :color="
                      taskResult?.data.type !== 'error' ? gl.mainColor : ''
                    "
                  >
                    {{ taskResult.data.yepLabel }}
                  </n-button>
                </div>
              </template>
            </base-modal-mobile>
            <base-modal-desktop
              v-else
              :maskClosable="false"
              :showCLoseButton="false"
              :type="taskResult?.data.type"
              :label="taskResult?.data.title"
              v-model:show="taskResult.show"
            >
              <template #default>
                <div
                  v-if="taskResult.data.allowHtml"
                  v-html="taskResult.data.content"
                ></div>
                <div v-else>{{ taskResult.data.content }}</div>
              </template>
              <template #footer>
                <div class="flex justify-end">
                  <n-button
                    class="text-white/90 rounded-md"
                    @click="someAction(taskResult.data)"
                    :type="taskResult?.data.type"
                    :color="
                      taskResult?.data.type !== 'error' ? gl.mainColor : ''
                    "
                  >
                    {{ taskResult.data.yepLabel }}
                  </n-button>
                </div>
              </template>
            </base-modal-desktop>
          </template>
          <post-advice-message />
          <post-dialog-messages />
        </template>
      </n-message-provider>
    </n-notification-provider>
  </n-config-provider>
</template>

<script>
// vue
import { ref, reactive, computed, onMounted } from 'vue';

// naive-ui
import {
    NButton,
    NConfigProvider,
    NSpin,
    NModal,
    GlobalThemeOverrides,
    NMessageProvider,
    NNotificationProvider,
    darkTheme,
} from 'naive-ui';

// services
import Api from '@services/api';
import RealmService from '@services/realmService';
import ProfileService from '@services/profileService';

// shared
import conf from '@shared/conf';

// coocke
import { useCookies } from 'vue3-cookies';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useUser } from '@store/user';
import { useRefs } from '@store/ts/refs';

import { useDashBoard } from '@store/ts/dashBoard';
import { useProfileFullInfo } from '@store/profileFullInfo';

// realm
// import RealmExecutorService from '@realm/RealmExecutorService';

// socket
import { io } from 'socket.io-client';

// components
import LeftPanel from '@components/left-panel/index.vue';
import RbHeader from '@components/rb-header/index.vue';
import MobileMenu from '@components/mobile-menu';
import RbMobileHeader from '@components/rb-header/mobile.vue';
import RootComponent from '@components/root-component/desktop.vue';
import PostAdviceMessage from '@components/post-advice-message';
import PostDialogMessages from '@components/post-dialog-messages';
import RootComponentMobile from '@components/root-component/mobile.vue';

// hljs
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
hljs.registerLanguage('javascript', javascript);

export default {
    components: {
        NSpin,
        NModal,
        NButton,
        RbHeader,
        LeftPanel,
        MobileMenu,
        RootComponent,
        RbMobileHeader,
        NConfigProvider,
        NMessageProvider,
        PostAdviceMessage,
        PostDialogMessages,
        RootComponentMobile,
        NNotificationProvider,
    },
    setup() {
        const user = useUser();
        const refs = useRefs();
        const gl = useGl();
        const env = useEnv();
        const dashBoard = useDashBoard();
        const profileFullInfo = useProfileFullInfo();

        // vars
        const tasks = ref([]);
        const taskResult = reactive({});
        const INTERNAL_PROCESSING_COOKIE = 'internalProcessing';
        const SHARED_PROCESSING_COOKIE = 'sharedProcessing';

        const onInitialRoutingDone = () => {
            let internalTasks = null,
                sharedTasks = null;

            try {
                internalTasks = unescape(
                    cookies.get(INTERNAL_PROCESSING_COOKIE, {
                        domain: conf.COOKIES.DOMAIN,
                        path: conf.COOKIES.PATH,
                    }),
                );
                internalTasks = JSON.parse(
                    Buffer.from(internalTasks, 'base64').toString('ascii'),
                );
                cookies.delete(INTERNAL_PROCESSING_COOKIE, {
                    domain: conf.COOKIES.DOMAIN,
                    path: conf.COOKIES.PATH,
                });
                cookies.delete(INTERNAL_PROCESSING_COOKIE);
            } catch (e) {
                //
            }

            try {
                sharedTasks = unescape(
                    cookies.get(SHARED_PROCESSING_COOKIE, {
                        domain: conf.COOKIES.DOMAIN,
                        path: conf.COOKIES.PATH,
                    }),
                );
                sharedTasks = JSON.parse(
                    Buffer.from(sharedTasks, 'base64').toString('ascii'),
                );
                cookies.delete(SHARED_PROCESSING_COOKIE, {
                    domain: conf.COOKIES.DOMAIN,
                    path: conf.COOKIES.PATH,
                });
                cookies.delete(SHARED_PROCESSING_COOKIE);
            } catch (e) {
                //
            }

            // DRAFT
            const DRAFT = [
                {
                    alert: {
                        token: '0645476a-f67b-11ee-9c83-bb169e9b2aa9',
                    },
                },
                {
                    confirm: {
                        token: 'b32e1caa-f73d-11ee-83ae-d517df7977dd',
                    },
                },
                {
                    redirectTo: 'https://google.com',
                },
            ];

            tasks.value = [
                // ...DRAFT,
                ...(internalTasks !== 'null' ? internalTasks : []),
                ...(sharedTasks !== 'null' ? sharedTasks : []),
            ];

            processRealmQueue();
        };

        const processRealmQueue = async () => {
            await describeInternal(tasks.value[0]);
        };

        const describeInternal = async (task) => {
            if (task && Object.prototype.hasOwnProperty.call(task, 'redirectTo')) {
                window.open(task.redirectTo, '_blank');
                tasks.value.shift();
                return void processRealmQueue();
            }

            if (task) {
                const prepare = (await RealmService.describeInternal(task)).data;

                if (Object.prototype.hasOwnProperty.call(prepare, 'errors')) {
                    tasks.value.shift();
                    return void processRealmQueue();
                }

                taskResult.show = !!task;
                taskResult.type = task ? Object.keys(task)[0] : undefined;
                taskResult.data = task ? prepare[Object.keys(task)[0]] : undefined;
            }

            // console.log('taskResult', taskResult.value);
        };

        // coockies
        const { cookies } = useCookies();

        const internalProcessing = cookies.get(INTERNAL_PROCESSING_COOKIE, {
            domain: conf.COOKIES.DOMAIN,
            path: conf.COOKIES.PATH,
        });

        const showPostAdviceMessages = computed(
            () => !!gl.postAdviceMessages && gl.postAdviceMessages?.length,
        );

        const init = async () => {
            const result = await Promise.allSettled([
                Api.getRefs(user),
                Api.getEnv(user),
                ProfileService.getBonusStepStatus(),
            ]);

            refs.refs = result[0].value;
            env.data = result[1].value;
            if (result[2].value?.data)
                refs.tasks = result[2].value.data.data;

            if (env.data?.data?.two_factor_auth) {
                gl.init = true;
                gl.stage = env.data.data.two_factor_auth;
                gl.stageState = 'awaitng';
                gl.twoFactorAuth = true;

                document
                    .querySelector('.lds-ellipsis__container')
                    .classList.add('hidden');

                return new Promise((resolve, reject) => {
                    const check2FAState = setInterval(async () => {
                        if (gl.stageState === 'inactive') {
                            clearInterval(check2FAState);
                            env.data = await Api.getEnv(user);
                            setGlConf();
                            gl.twoFactorAuth = false;
                        } else if (gl.stageState === 'canceled') {
                            gl.twoFactorAuth = false;
                            clearInterval(check2FAState);
                            resolve();
                        }
                    }, 200);
                });
            }
        };

        const themeOverrides = computed(() => ({
            common: {
                primaryColor: gl.mainColor,
                primaryColorHover: gl.mainColor,
                primaryColorPressed: gl.mainColor,
            },
            Tabs: {
                barColor: gl.mainColor,
                tabTextColorActiveLine: gl.mainColor,
                tabTextColorHoverLine: gl.mainColor,
            },
            Checkbox: {
                colorChecked: gl.mainColor,
                borderFocus: `1px solid ${gl.mainColor}`,
                borderChecked: `1px solid ${gl.mainColor}`,
                boxShadowFocus: '0 0 0 2px rgba(73, 73, 217, 0.3)',
            },
            Radio: {
                boxShadowFocus: 'inset 0 0 0 1px rgba(73, 73, 217, 0.24)',
                boxShadowActive: `inset 0 0 0 1px ${gl.mainColor}`,
                boxShadowHover: `inset 0 0 0 1px ${gl.mainColor}`,
                buttonBoxShadowHover: `inset 0 0 0 1px ${gl.mainColor}`,
                buttonBoxShadowFocus: `inset 0 0 0 1px ${gl.mainColor}`,
                dotColorActive: gl.mainColor,
                buttonBorderColorActive: gl.mainColor,
                buttonBorderColorHover: gl.mainColor,
                buttonColorActive: gl.mainColor,
                buttonTextColorHover: gl.mainColor,
            },
            DataTable: {
                loadingColor: gl.mainColor,
            },
            Pagination: {
                itemTextColorHover: gl.mainColor,
                itemTextColorPressed: gl.mainColor,
                itemTextColorActive: gl.mainColor,
                itemBorderActive: '1px solid rgba(73, 73, 217, 0.24)',
            },
            Spin: {
                textColor: gl.mainColor,
                color: gl.mainColor,
            },
            Input: {
                caretColor: gl.mainColor,
                loadingColor: gl.mainColor,
                colorFocus: 'rgba(73, 73, 217, 0.1)',
                borderHover: `1px solid ${gl.mainColor}`,
                borderFocus: `1px solid ${gl.mainColor}`,
                boxShadowFocus: '0 0 8px 0 rgba(73, 73, 217, 0.3)',
            },
            Select: {
                loadingColor: gl.mainColor,
                colorFocus: 'rgba(73, 73, 217, 0.1)',
                borderHover: `1px solid ${gl.mainColor}`,
                borderFocus: `1px solid ${gl.mainColor}`,
                menuBoxShadow: 'none',
            },
            Slider: {
                fillColor: gl.mainColor,
                fillColorHover: gl.mainColor,
                indicatorColor: gl.mainColor,
                dotBorder: '2px solid rgba(73, 73, 217, 0.3)',
                dotBorderActive: `2px solid ${gl.mainColor}`,
            },
            Switch: {
                railColorActive: gl.mainColor,
            },
        }));

        const someAction = (data) => {
            // if (data.terminal) {

            // }

            taskResult.show = false;
            tasks.value.shift();

            return void processRealmQueue();
        };

        const socketInit = (URL) => {
            if (!URL) return;

            const auth =
        process.env.NODE_ENV === 'production'
            ? cookies.get('rb_session', {
                domain: conf.COOKIES.DOMAIN,
                path: conf.COOKIES.PATH,
            })
            : 'sRP9SjiVVSclO7U5lwgA5R1Vx8kJdf_5';

            let socket = new WebSocket(URL + '?sess=' + auth);

            socket.onmessage = function (event) {
                const data = JSON.parse(event.data);

                // ticker
                if (data.channel === 'ticker') {
                    const i = gl.ticker.findIndex(
                        ({ name }) => name === data.ticker.name,
                    );

                    ~i ? (gl.ticker[i] = data.ticker) : gl.ticker.push(data.ticker);
                }

                // notifications
                if (data.channel === 'notifications') {
                    gl.notifications = data.notifications;
                }

                // balance
                if (data.channel === 'balance') {
                    gl.balance = data.balance;
                }

                // timeToShutdown
                if (data.channel === 'timeToShutdown') {
                    Object.keys(gl.timeToShutdown).forEach((key) => {
                        gl.timeToShutdown[key] = data[key];

                        if (key === 'status') {
                            env.data.data.env.status = data[key];
                        }
                    });
                }

                // зштп
                if (data.channel === 'ping') {
                    // gl.notifications = data.notifications;
                }
            };

            socket.onclose = function (e) {
                console.log(
                    'Socket is closed. Reconnect will be attempted in 1 second.',
                    e.reason,
                );
                setTimeout(function () {
                    socketInit(URL);
                }, 1000);
            };

            socket.onerror = function (err) {
                console.error(
                    'Socket encountered error: ',
                    err.message,
                    'Closing socket',
                );
                socket.close();
            };

            // const socket = io(URL, {
            //     query: {
            //         sess: auth,
            //     },
            //     path: '/AuthWs',
            //     addTrailingSlash: false,
            //     transports: ['websocket'],
            // });

            // socket.on('connect', () => {
            //     console.log('connect');
            // });

            // socket.on('connect_error', (err) => {
            //     console.log(`connect_error due to ${err.message}`);
            // });
        };

        const setGlConf = () => {
            gl.balance = {
                balance: env.data.data?.env.balance,
                balance_usdt: env.data.data?.env.balance_usdt,
            };

            gl.timeToShutdown = {
                timeToShutdown: env.data.data?.env.timeToShutdown,
                timeToStop: env.data.data?.env.timeToStop,
                status: env.data.data?.env.status,
            };

            socketInit(env.data.data?.env.ws_url);
        };

        onMounted(async () => {
            gl.build_timestamp = window.rb.build_timestamp;
            onInitialRoutingDone();

            await init();
            setGlConf();

            gl.init = true;

            // установка темы
            if (gl.darkTheme) document.documentElement.classList.add('dark');

            document
                .querySelector('.lds-ellipsis__container')
                .classList.add('hidden');
            gl.screenWidth = window.screen.width;
        });

        return {
            gl,
            hljs,
            darkTheme,
            taskResult,
            someAction,
            themeOverrides,
            showPostAdviceMessages,
        };
    },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
