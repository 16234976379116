<template>
<section>
    <div :class="labelPosition === 'left' ? 'flex items-center' : 'h-full flex flex-col justify-between'">
        <div
            class="flex items-center"
            :style="labelPosition === 'left' ? `width: ${labelWidth + 'px' || 'fit-content'};` : ''">
            <div
                v-if="label"
                class="text-md text-gray-600 dark:text-white/75"
                :class="[status === 'error' ? 'text-red-400' : '', nowrap ? 'whitespace-nowrap' : 'flex-grow text-left']">
                {{ label }}
            </div>
            <slot name="afterTitle"></slot>
            <n-popover
                v-if="help"
                trigger="hover"
                class="max-w-[500px] rounded-md"
                :class="popoverType === 'info' ? 'bg-main' : 'bg-yellow-600'"
                placement="top">
                <template #trigger>
                    <n-icon
                        class="text-main cursor-pointer text-lg ml-2 mt-[2px]"
                        :class="popoverType === 'info' ? 'text-main' : 'text-yellow-600'">
                        <QuestionCircle20Filled />
                    </n-icon>
                </template>
                <span class="notes text-xs text-white" v-html="help"></span>
            </n-popover>
            <n-popover
                v-if="helpWarning"
                trigger="hover"
                class="max-w-[500px] rounded-md bg-yellow-600"
                placement="top">
                <template #trigger>
                    <n-icon
                        class="cursor-pointer text-lg ml-2 text-yellow-600 mt-[2px]">
                        <Warning24Filled />
                    </n-icon>
                </template>
                <span class="notes text-xs text-white" v-html="helpWarning"></span>
            </n-popover>
        </div>
        <div class="flex flex-grow" :class="labelPosition == 'left' ? 'items-center' : 'items-end'">
            <n-select
                v-if="!hideSelect"
                filterable
                class="text-left"
                :class="[labelPosition === 'left' ? 'ml-2' : '', label && labelPosition !== 'left' ? 'mt-2' : '']"
                :size="size"
                :multiple="multiple"
                :clearable="clearable"
                :value="localValue != -1 ? localValue : null"
                :options="optionsRef"
                :placeholder="placeholder"
                :loading="loading"
                :disabled="loading || disabled"
                :status="status"
                @update:value="$emit('update:value', $event)"
                @search="$emit('search', $event)" />
            <slot name="after"></slot>
        </div>
    </div>
    <div
        v-if="$slots.info"
        class="flex-grow pl-2"
        :style="`margin-left: ${labelWidth}px;`">
        <slot name="info"></slot>
    </div>
    <slot name="messages"></slot>
    <div v-if="status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ msg }}</div>
</section>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NIcon,
    NSelect,
    NPopover } from 'naive-ui';

// icons
import { QuestionCircle20Filled, Warning24Filled } from '@vicons/fluent';

export default {
    name: 'rb-select',
    emits: ['update:value'],
    props: {
        label: {
            type: String,
        },
        labelWidth: {
            type: [ String, Number ],
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['left', 'top'].includes(prop),
        },
        size: {
            type: String,
            default: 'medium',
            validator: prop => ['tiny', 'small', 'medium', 'large'].includes(prop),
        },
        help: {
            type: String,
        },
        helpWarning: {
            type: String,
        },
        largeHelp: {
            type: Boolean,
        },
        helpPosition: {
            type: String,
            default: 'right',
        },
        value: {
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            default: () => useI18n().t('select'),
        },
        clearable: {
            type: Boolean,
        },
        multiple: {
            type: Boolean,
        },
        defaultValue: {
            type: Boolean,
            default: -1,
        },
        loading: {
            type: Boolean,
        },
        hideSelect: {
            type: Boolean,
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
        popoverType: {
            type: String,
            default: 'info',
            validator: prop => ['info', 'warning'].includes(prop), 
        },
        nowrap: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        NIcon,
        NSelect,
        NPopover,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup(props) {
        // vars
        const { _ } = window;

        // i18n
        const { t } = useI18n();

        const optionsRef = computed(() => {
            const arr = _.cloneDeep(props.options);
            if (props.defaultValue)
                arr.unshift({
                    label: `- ${t('select')} -`,
                    value: props.defaultValue,
                    style: {
                        color: 'gray',
                    },
                });

            return arr;
        });

        const localValue = computed(() => {
            if (props.multiple) {
                return props.value;
            } else {
                const res = optionsRef.value.find(item => {
                    if (item.value != props.value && Object.prototype.hasOwnProperty.call(item, 'children')) {
                        return item.children.find(({ value }) => value == props.value);
                    }
                    
                    return item.value == props.value;
                });
                
                return res
                    ? props.value
                    : props.defaultValue;
            }
        });

        return {
            localValue,
            optionsRef,
        };
    },
};
</script>