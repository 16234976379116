import { h } from 'vue';
import { defineStore } from 'pinia';
import LocalStorage from '@services/ts/localStorage';

// interfaces
import { newBuild } from './interfaces';

export const useGl = defineStore('gl', {
    state: (): { [key: string]: any } => ({
        init: false,
        stage: null,
        darkTheme:
            LocalStorage.has('darkTheme')
                ? LocalStorage.getItem('darkTheme')
                : window.matchMedia('(prefers-color-scheme: dark)').matches,
        isPrivate: false,
        loading: false,
        openPanel: false,
        homeLoading: false,
        mainColor: '#1A6DE0',
        showLoading: false,
        loadingMessage: '',
        screenWidth: 0,
        secondMenu: false,
        isMobile: window.innerWidth <= 768,
        serverError: false,
        stageErrors: {},
        stageExtra: null,
        stageState: 'inactive',
        showTopUpYourBalance: false,
        showPassModal: false,
        twoFactorAuth: false,
        postAdviceMessages: null,
        postDialogMessages: null,
        new_build: null,
        build_timestamp: null,
        canAutoUpdate: false,
        ticker: [],
        notifications: [],
        balance: null,
        timeToShutdown: null,
        notification: null,
    }),
    getters: {
        inited(state) {
            return state.init;
        },
    },
    actions: {
        cleanError(obj: string, key: string) {
            delete this[obj][key];
        },
        setNewBuild(data: newBuild) {
            if (!data) return;
		
            let { message, autorefresh_in } = data;
		
            const curUrl = window.location.href;
            const anchorStartIdx = message.indexOf('|');
            const anchorEndIdx = message.lastIndexOf('|');
            const anchorText = message.substring(anchorStartIdx + 1, anchorEndIdx);
            const link = `<a href="${window.location.href}" onclick="window.location.reload();return false;">${anchorText}</a>`;
            const newMessage = `${message.substring(0, anchorStartIdx - 1)} ${link}${message.substring(anchorEndIdx + 1)}`;

            this.new_build = {...data, message: newMessage};

            const nbInterval = setInterval(() => {
                if (autorefresh_in <= 0) {
                    clearInterval(nbInterval);
                    return window.location.reload();
                }
                
                this.new_build.autorefresh_in = parseInt(autorefresh_in.toString(), 10) - 1;
            }, 1000);
        },
        showNotification({ type = 'success', msg = '' }) {
            this.notification[type]({
                content: () => h('div', {
                    class: 'notes',
                    innerHTML: msg,
                }),
                duration: 3000,
                keepAliveOnHover: true,
            });
        },
    },
});